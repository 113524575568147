import React from "react"
import Accordion from "react-bootstrap/Accordion"
import Card from "react-bootstrap/Card"

import FaqLayout from "../../components/faq-layout"

export default () => (
  <FaqLayout subMenuActivo="reclamos">
    <Accordion defaultActiveKey="0">
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="0">
          <h6>
            <a className="collapsed" href="#a" data-toggle="collapse">
              Hice la solicitud y no me llegó la póliza todavía. ¿Cómo realizo el reclamo?
            </a>
          </h6>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <p>
              En un lapso de 48 hs hábiles para la entrega de póliza final vía mail, mientras tanto
              podés circular con el Certificado de Cobertura.
            </p>
            <p>
              En caso de que este último no esté habilitado en tu casilla de mail podés ingresar a
              “Contacto” en el sitio de web del seguro y solicitarlo cargando un ticket de reclamo
              para que un asesor pueda ocuparse del caso.
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="1">
          <h6>
            <a className="collapsed" href="#b" data-toggle="collapse">
              No me hicieron el débito del seguro. ¿Cómo lo puedo abonar?
            </a>
          </h6>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="1">
          <Card.Body>
            <p>
              Web Del Seguro no realiza cobranzas, es la Compañía aseguradora quien efectuara
              mensualmente el importe de tu cuota.
            </p>
            <p>
              En caso de presentarse alguna irregularidad con el cobro del seguro la Compañía nos
              informará al respecto para que, desde aquí, te notifiquemos vía mail y SMS la
              situación.
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="2">
          <h6>
            <a className="collapsed" href="#b" data-toggle="collapse">
              Me llegó la póliza, pero tiene un error. ¿Cómo la cambio?
            </a>
          </h6>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="2">
          <Card.Body>
            <p>
              Ingresando a “Contacto” podrás comunicarte indicando la información incorrecta para
              que gestionemos un endoso de póliza con los datos correctos.
            </p>
            <p>
              Una vez arreglado el error se te enviará la nueva documentación con los datos
              actualizados.
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="3">
          <h6>
            <a className="collapsed" href="#b" data-toggle="collapse">
              No me llegó la renovación. ¿Qué hago?
            </a>
          </h6>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="3">
          <Card.Body>
            <p>Las renovaciones son automáticas, por lo cual no es necesitas solicitarlas.</p>
            <p>
              Al finalizar la vigencia de póliza te enviaremos a tu mail la nueva documentación.
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  </FaqLayout>
)
